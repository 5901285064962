<template>
  <div class="container-fluid d-flex flex-column">
    <main>
      <div class="row d-flex justify-content-center">
        <div class="col-auto">
          <h1>Impact Sessions</h1>
        </div>
      </div>
      <div class="row">
        <!-- Dynamic session rendering -->
        <div class="col-md-6" v-for="(sessionGroup, index) in chunkedSessions" :key="index">
          <div 
            class="session" 
            v-for="session in sessionGroup" 
            :key="session.id">
            <div class="row mt-2">
              <div class="col-lg-2 offset-lg-1">
                <img class="img-fluid" :src="session.speaker_image || 'https://placebeard.it/250/250'" alt="Speaker Image" />
              </div>
              <div class="col-lg-8">
                <div 
                  class="session_full" 
                  :style="{ display: Number(session.percentage) === 100 ? 'flex' : 'none' }">
                  Full
                </div>
                <div 
                  class="session_info" 
                  :class="{ 'full': Number(session.percentage) === 100 }">
                  <h2>{{ session.speaker_name }} ({{ session.room }})</h2>
                  <p>{{ formatTime(session.start_at) }} | {{ session.name }}</p>
                </div>
                <div class="progress">
                  <div
                    class="progress-bar session-progress"
                    :style="getProgressBarStyle(session.percentage)">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- Footer -->
    <div id="key">
      <div class="progress">
        <div class="progress-bar" style="width:30%;"></div>
      </div>
      <div class="labels">
        <span>Empty</span>
        <span>Full</span>
      </div>
    </div>

    <img :src="footerImage" class="footer" alt="Footer" />
  </div>
</template>

<script>
import axios from "axios";
import { ref, computed, onMounted, onUnmounted } from "vue";
import footerImage from "@/assets/images/seek_block_logo.png";

export default {
  data() {
    return {
      footerImage,
    };
  },
  setup() {
    const sessions = ref([]);
    const intervalId = ref(null);

    // http://localhost:8080/STL?day=1&round_letter=A
    const fetchSessions = async () => {
      try {
        const baseUrl = process.env.VUE_APP_API_URL;
        const urlParts = window.location.pathname.split("/");

        // Extract the `room`, `dayNumber`, and `dailyRoundNumber` if present
        const eventSlug = urlParts[urlParts.length - 1];

        const urlParams = new URLSearchParams(window.location.search);
        const dayNumber = urlParams.get("day");
        const dailyRoundNumber = urlParams.get("round_letter");

        // Construct the API URL conditionally
        let fullUrl;
        if (dayNumber && dailyRoundNumber) {
          fullUrl = `${baseUrl}/sessions?day=${dayNumber}&daily_round_number=${dailyRoundNumber}&event=${eventSlug}`;
        } else {
          fullUrl = `${baseUrl}/sessions?time=now-general&event=${eventSlug}`;
        }
        const response = await axios.get(fullUrl);

        sessions.value = response.data.sort((a, b) => new Date(a.start_at) - new Date(b.start_at));
      } catch (error) {
        console.error("Error fetching sessions:", error);
      }
    };

    onMounted(() => {
      fetchSessions();
      intervalId.value = setInterval(fetchSessions, 5000); // Fetch every 5 seconds
    });

    onUnmounted(() => {
      if (intervalId.value) clearInterval(intervalId.value);
    });

    // Group sessions into chunks for a two-column layout
    const chunkedSessions = computed(() => {
      const chunkSize = Math.ceil(sessions.value.length / 2);
      return [sessions.value.slice(0, chunkSize), sessions.value.slice(chunkSize)];
    });

    return {
      sessions,
      chunkedSessions,
    };
  },
  methods: {
    formatTime(datetime) {
      const date = new Date(datetime);
      return new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      }).format(date).replace(/AM|PM/gi, "").trim();
    },
    getProgressBarStyle(percentage) {
      return {
        width: percentage + "%",
      };
    },
  },
};
</script>

<style scoped>
.footer {
  width: 13vh; /* Adjust this to the actual size. This was auto and max-width was 200px */
  position: fixed; /*was absolute*/
  bottom: 25px; /*was 10px*/
  right: 25px; /*was 10px*/
  z-index: 9999; /* Ensure it's not overlapped by other elements */
  transition: all 0.3s ease; /* Optional: Add smooth transition */
}

.footer img {
  width: 100%;  /* Makes the logo fill the container */
  height: auto; /* Keep the aspect ratio */
}



</style>
