import { createRouter, createWebHistory } from 'vue-router';
import CurrentSessionsForEvent from '../components/CurrentSessionsForEvent/CurrentSessionsForEvent.vue';
import AdminEdit from '../components/AdminEdit/AdminEdit.vue';
import RoomSpecificBoard from '@/components/RoomSpecificBoard/RoomSpecificBoard.vue';

const routes = [
  {
    path: '/:eventSlug/:dayNumber?/:dailyRoundNumber?',
    name: 'CurrentSessionsForEvent',
    component: CurrentSessionsForEvent
  },
  {
    path: '/sessions/718991fd-b88f-43c1-9650-3c9949f26279',
    name: 'AdminEdit',
    component: AdminEdit
  },
  {
    path: '/room/:room/:dayNumber?/:dailyRoundNumber?',
    name: 'RoomSpecificBoard',
    component: RoomSpecificBoard
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
