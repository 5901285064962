<template>
  <div>
    <div id="current-session" class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-xs-12">
          <div :style="{ display: Number(mainSession?.percentage) === 100 ? 'flex' : 'none' }" class="session_full">Session Full</div>
          <h1>{{ mainSession?.room || 'Room Name' }}</h1>
          <div class="progress">
            <div class="progress-bar session-progress" :style="{ width: mainSession?.percentage + '%' }"></div>
          </div>
          <div class="labels">
            <span>Empty</span>
            <span>Full</span>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid d-flex flex-column">
      <div class="row d-flex justify-content-center">
        <div class="col-auto">
          <h3>Additional Impact Sessions</h3>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <!-- Dynamic sessions using the fetched data -->
          <SessionCard 
            v-for="session in sessions" 
            :key="session.id" 
            :img-src="session.speaker_image || 'https://placebeard.it/250/250'" 
            :title="`${session.speaker_name} (${session.room})`"
            :subtitle="formatTime(session.start_at) + ' | ' + session.name"
            :progress="session.percentage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { DateTime } from "luxon";

// Subcomponent for individual session cards
const SessionCard = {
  props: ['imgSrc', 'title', 'subtitle', 'progress'],
  template: `
    <div class="session container ps-2 pe-2">
      <div class="row mt-2">
        <div class="col-2 p-0">
          <img class="img-fluid" :src="imgSrc" alt="Session Image">
        </div>
        <div class="col-10">
          <div class="session_info" :class="{ 'full': Number(progress) === 100 }">
            <h2>{{ title }}</h2>
            <p class="truncate">{{ subtitle }}</p>
          </div>
          <div class="progress">
            <div class="progress-bar session-progress" :style="{ width: progress + '%' }"></div>
          </div>
          <div :style="{ display: Number(progress) === 100 ? 'flex' : 'none' }" class="session_full">Full</div>
        </div>
      </div>
    </div>
  `
};

export default {
  name: 'SessionLayout',
  components: {
    SessionCard
  },
  data() {
    return {
      sessions: [],
      mainSession: null
    };
  },
  mounted() {
    this.fetchSessions();
    setInterval(this.fetchSessions, 5000); // Fetch data every 5 seconds
  },
  methods: {
    async fetchSessions() {
      try {
        const baseUrl = process.env.VUE_APP_API_URL;
        const urlParts = window.location.pathname.split("/");

        // Extract the `room`, `dayNumber`, and `dailyRoundNumber` if present
        const rawRoom = urlParts[urlParts.length - 1];
        const room = rawRoom.replace(/_/g, " "); // Replace underscores with spaces
        console.log("Room:", rawRoom);

        const urlParams = new URLSearchParams(window.location.search);
        const dayNumber = urlParams.get("day");
        const dailyRoundNumber = urlParams.get("round_letter");

        // Construct the API URL conditionally
        let fullUrl;
        if (dayNumber && dailyRoundNumber) {
          fullUrl = `${baseUrl}/sessions?day=${dayNumber}&daily_round_number=${dailyRoundNumber}`;
        } else {
          fullUrl = `${baseUrl}/sessions?time=now-room-specific`;
        }

        const response = await axios.get(fullUrl);

        // Sorting sessions by their start time
        const sessionData = response.data.sort((a, b) => new Date(a.start_at) - new Date(b.start_at));
        

        console.log("Session Data:", sessionData);

        // Get the current time in MST
        const nowMST = DateTime.now().setZone("America/Denver");
        const currentTimeMST = nowMST.toFormat("HH:mm:ss");
        console.log("Current Time MST:", currentTimeMST);

        // Find sessions matching the room
        const matchingSessions = sessionData.filter(session => session.room === room);
        console.log("Matching Sessions:", matchingSessions);

        if (matchingSessions.length === 0) {
          console.warn("No sessions found for the room.");
          this.sessions = [];
          this.mainSession = null;
          return;
        }

        // Determine which sessions to display based on logic
        let filteredSessions = [];
        const timeThreshold1 = "14:40:00"; // 2:40 PM MST
        const timeThreshold2 = "14:55:00"; // 2:55 PM MST

        // If the earliest main room session is an A1, show A1 and A2 sessions until 2:40 PM MST
        // If the earlierst main room session is an A2, show A1 and A2 sessions until 2:55 PM MST
        if (matchingSessions.some(session => session.daily_round_number === "A1")) {
          if (currentTimeMST < timeThreshold1) {
            filteredSessions = sessionData.filter(session => ["A1", "A2"].includes(session.daily_round_number));
          } else {
            filteredSessions = sessionData.filter(session => ["B1", "B2"].includes(session.daily_round_number));
          }
        } else if (matchingSessions.some(session => session.daily_round_number === "A2")) {
          if (currentTimeMST < timeThreshold2) {
            filteredSessions = sessionData.filter(session => ["A1", "A2"].includes(session.daily_round_number));
          } else {
            filteredSessions = sessionData.filter(session => ["B1", "B2"].includes(session.daily_round_number));
          }
        } // if the room has B1 or B2, just show the B1 and B2 sessions - this is a failsafe
        else {
          filteredSessions = sessionData.filter(session => ["B1", "B2"].includes(session.daily_round_number));
        }


        console.log("Filtered Sessions:", filteredSessions);

        // Set the main session and other sessions
        this.mainSession = matchingSessions.find(session => filteredSessions.includes(session)) || null;
        this.sessions = filteredSessions.filter(session => session.id !== this.mainSession?.id);
        console.log("Main Session:", this.mainSession);
      } catch (error) {
        console.error("Error fetching sessions:", error);
      }
    },
    formatTime(datetime) {
      const date = new Date(datetime);
      return new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true
      }).format(date).replace(/AM|PM/gi, "").trim();
    }
  }
};
</script>

<style scoped>
/* Scoped styles for this specific component (optional overrides) */
</style>
