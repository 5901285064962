<template>
  <div>
    <h1>All Sessions</h1>
    <!-- Filters -->
    <input v-model="filters.eventName" placeholder="Filter by event name">
    <select v-model="filters.dailyRoundNumbers" multiple>
      <option v-for="number in availableDailyRoundNumbers" :key="number" :value="number">
        {{ number }}
      </option>
    </select>
    <input type="date" v-model="filters.startDate" placeholder="Filter by start date">
    
    <div v-if="showSuccessMessage" class="success-message">
      Update successful!
    </div>

    <!-- Displaying filtered sessions in a table -->
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Event</th>
            <th>Round Number</th>
            <th>Speaker</th>
            <th>Room</th>
            <th>Percentage</th>
            <th>Start At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="session in filteredSessions" :key="session.id">
            <td><input type="text" v-model="session.name" /></td>
            <td>{{ session.event_name }}</td>
            <td>
              <select v-model="session.daily_round_number">
                <option v-for="number in availableDailyRoundNumbers" :key="number" :value="number">
                  {{ number }}
                </option>
              </select>
            </td>
            <td>{{ session.speaker_name }}</td>
            <td><input type="text" v-model="session.room" /></td>
            <td><input type="number" v-model="session.percentage" /></td>
            <td><input type="datetime-local" v-model="session.start_at" /></td>
            <td>
              <button @click="updateSession(session)">Save</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<!-- Existing CSS and Script tags -->



<style scoped src='./AdminEdit.css'></style>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      sessions: [],
      showSuccessMessage: false,
      filters: {
        event: '',
        eventName: '',
        dailyRoundNumbers: [],
        startDate: ''
      },
      availableDailyRoundNumbers: ['A1', 'A2', 'B1', 'B2']
    };
  },
  computed: {
    filteredSessions() {
    return this.sessions.filter(session => {
      const startDateMatch = this.filters.startDate ? session.start_at.startsWith(this.filters.startDate) : true;
      const dailyRoundNumberMatch = this.filters.dailyRoundNumbers.length ? this.filters.dailyRoundNumbers.includes(session.daily_round_number) : true;
      return (
        (this.filters.eventName === '' || session.event_name.includes(this.filters.eventName)) &&
        startDateMatch &&
        dailyRoundNumberMatch
      );
    });
  }
  },
  methods: {
    fetchSessions() {
      const apiUrl = `${process.env.VUE_APP_API_URL}/sessions`;
      axios.get(apiUrl)
        .then(response => {
          this.sessions = response.data;
        })
        .catch(error => {
          console.error('Error fetching sessions:', error);
        });
    },
    updateSession(session) {
      // Implement the logic to send updated session data to your server
      const apiUrl = `${process.env.VUE_APP_API_URL}/sessions/${session.id}`;

      console.log(session);
      axios.put(apiUrl, session)
        .then(response => {
          console.log('Session updated successfully:', response.data);
          this.showSuccessMessage = true;
          setTimeout(() => {
            this.showSuccessMessage = false;
          }, 2000);
          
        })
        .catch(error => {
          console.error('Error updating session:', error);
          // Handle error
        });
    }
  },
  mounted() {
    this.fetchSessions();
  }
};
</script>


<style>
  .success-message{
    position:fixed;
    top:0;
    left:0;
    right:0;
}
</style>